//import '@babel/polyfill';

//import {common} from "../modules/common";
//import {changeImg} from "../modules/changeImg";
import {smoothScroll} from "../modules/smoothScroll";
//import {smoothLink} from "../modules/smoothLink";
//import {pagetop} from "../modules/pagetop";
//import {scrollAnimation} from "../modules/scrollAnimation";
import {nav} from "../modules/nav";
import {swiper} from "../modules/swiper";
// import {modal} from "../modules/modal";

//common();
//changeImg();
smoothScroll();
//smoothLink();
//pagetop();
//scrollAnimation();
nav();
swiper();
// modal();


$(function() {
  if($('.front-information').length){
    $.ajax({
      type: 'GET',
      url: 'https://www.roselegance-onlineshop.jp/information/wp-json/wp/v2/org_api?_jsonp=callback',//コールバック関数名を指定する。
      dataType: 'jsonp',
      jsonpCallback: 'callback',//コールバック関数名を指定する。
    }).done(function(jsonp){
      let title, link, datetime, time;

      $(jsonp).each(function(index,el){
        title = (jsonp[index]['title']);
        link = (jsonp[index]['link']);
        datetime = (jsonp[index]['datetime']);
        time = (jsonp[index]['time']);

        $('.front-information__items').append('<li class="front-information__item"><a href="' + link + '"><time datetime="' + datetime + '">' + time + '</time><span>' + title + '</span></a></li>');
      });
    }).fail(function(json){
        console.error('WordPressのブログ記事取得に失敗しました。')
    });
  }

  $(window).on('load', function(){
    if($('#age-check').prop('checked')){
      $('.cart-pager__btn, .AmazonPayButton').attr('aria-disabled', 'false');
    } else {
      $('.cart-pager__btn, .AmazonPayButton').attr('aria-disabled', 'true');
    }
  });

  $('#age-check').on('click', function(){
    if($(this).prop('checked')){
      $('.cart-pager__btn, .AmazonPayButton').attr('aria-disabled', 'false');
    } else {
      $('.cart-pager__btn, .AmazonPayButton').attr('aria-disabled', 'true');
    }
  });

  $('.entry-check').on('click', function(){
    if($('#privacy-check').prop('checked')){
      $('.ec-blockBtn--action').attr('aria-disabled', 'false');
    } else {
      $('.ec-blockBtn--action').attr('aria-disabled', 'true');
    }
  });
});
