/* nav open/close
*********************************************/
export function nav() {
	const headerBtn = document.querySelector('.header__btn'),
				nav = document.querySelector('.nav'),
				navItem = document.querySelectorAll('.nav__item a');

	headerBtn.addEventListener('click', () => {
		headerBtn.classList.toggle('is-opened');
		nav.classList.toggle('is-opened');
		document.body.classList.toggle('is-nav');
	});

	for (let i = 0; i < navItem.length; i++) {
		navItem[i].addEventListener('click', () => {
			headerBtn.classList.remove('is-opened');
			nav.classList.remove('is-opened');
			document.body.classList.remove('is-nav');
		});
	}
}
