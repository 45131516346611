export function swiper() {
	const frontSlide = new Swiper('.front-products__slide', {
		autoplay: {
			delay: 4000,
			disableOnInteraction: true
		},
	  loop: true,
		speed: 1000,
	  autoHeight: false,
		slidesPerView	: 6,
		spaceBetween: 21,
		breakpoints: {
	    414: {
	      slidesPerView: 1,
	      spaceBetween: 10
	    },
			768: {
	      slidesPerView: 2,
	      spaceBetween: 20
	    },
	  },
		navigation: {
	    nextEl: '.swiper-button-next',
	    prevEl: '.swiper-button-prev',
	  },
	});
}
